<template>
  <VSimpleTable />
</template>

<script>
export default {
  name: 'TTSimpleTable',
  props: {
    hideDefaultFooter: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>

</style>
