<template>
  <div>
    <VRow>
      <VCol cols="12">
        <h2>Fields only</h2>
      </VCol>
    </VRow>
    <VRow class="mb-8">
      <VCol cols="4">
        <h4 class="mb-2 tt-light-mono-64--text tt-text-body-1">
          80px
        </h4>
        <TTTextarea
          v-model="value"
          large
          placeholder="Placeholder"
          :rules="[rules.required, rules.minLength(3)]"
        />
      </VCol>
    </VRow>
    <VRow class="mb-8">
      <VCol cols="4">
        <h4 class="mb-2 tt-light-mono-64--text tt-text-body-1">
          64px
        </h4>
        <TTTextarea
          v-model="value"
          placeholder="Placeholder"
          :rules="[rules.required, rules.minLength(3)]"
        />
      </VCol>
    </VRow>
    <VRow class="mb-8">
      <VCol cols="4">
        <h4 class="mb-2 tt-light-mono-64--text tt-text-body-1">
          56px
        </h4>
        <TTTextarea
          v-model="value"
          small
          placeholder="Placeholder"
          :rules="[rules.required, rules.minLength(3)]"
        />
      </VCol>
    </VRow>
    <VRow class="mb-8">
      <VCol cols="4">
        <h4 class="mb-2 tt-light-mono-64--text tt-text-body-1">
          48px
        </h4>
        <TTTextarea
          v-model="value"
          x-small
          placeholder="Placeholder"
          :rules="[rules.required, rules.minLength(3)]"
        />
      </VCol>
    </VRow>
  </div>
</template>

<script>
/* eslint-disable vue/require-name-property */
export default {
  data() {
    return {
      value: '',
      rules: {
        required: (v) => v.trim().length > 0 || 'Обязательное поле',
        minLength: (min) => (v) => (v.length >= min) || `Минимальная длина ${min} симв.`,
      },
    };
  },
};
</script>
