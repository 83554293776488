


























































































import Vue, { PropType } from 'vue';
import { humanReadableFileSize } from '@/helpers';
import TTChip from '../TTChip/TTChip.vue';
import TTBtn from '../TTBtn/TTBtn.vue';
import TTPrompt from '../TTPrompt/TTPrompt.vue';
import TTTooltip from '../TTTooltip/TTTooltip.vue';

export type IAttachmentMode = 'view' | 'edit';

export type IAttachmentType =
  | 'word'
  | 'excel'
  | 'image'
  | 'powerpoint'
  | 'pdf'
  | 'audio'
  | 'video'
  | 'code'
  | 'archive'
  | 'unknown';

const EXTENSIONS_TO_TYPE_MAP: Record<string, IAttachmentType> = {
  doc: 'word',
  docx: 'word',
  xls: 'excel',
  jpg: 'image',
  jpeg: 'image',
  tif: 'image',
  gif: 'image',
  png: 'image',
  ppt: 'powerpoint',
  pptx: 'powerpoint',
  pdf: 'pdf',
  mp3: 'audio',
  wav: 'audio',
  mp4: 'video',
  mov: 'video',
  css: 'code',
  zip: 'archive',
  rar: 'archive',
};

const TYPE_TO_ICON_MAP: Record<IAttachmentType, string> = {
  word: 'file-word',
  excel: 'file-excel',
  image: 'file-image',
  powerpoint: 'file-powerpoint',
  pdf: 'file-pdf',
  audio: 'file-audio',
  video: 'file-video',
  code: 'file-code',
  archive: 'file-archive',
  unknown: 'file-alt',
};

const TYPE_TO_ICON_COLOR_MAP: Record<IAttachmentType, string> = {
  word: '#1565C0',
  excel: '#118E43',
  image: '#00ACC1',
  powerpoint: '#E64A19',
  pdf: '#D32F2F',
  audio: '#F06292',
  video: '#7E57C2',
  code: '#4DD0E1',
  archive: '#42A5F5',
  unknown: '#FFA000',
};

const getIconForType = (type: IAttachmentType): string => TYPE_TO_ICON_MAP[type] ?? `file-${type}`;
const getIconColorForType = (type: IAttachmentType): string => TYPE_TO_ICON_COLOR_MAP[type] ?? '#A0B0B8';
const getTypeForExtension = (extension: string | undefined): IAttachmentType => {
  if (!extension) return 'unknown';

  return EXTENSIONS_TO_TYPE_MAP[extension] ?? 'unknown';
};

export default Vue.extend({
  name: 'TTAttachment',

  components: {
    TTChip,
    TTBtn,
    TTPrompt,
    TTTooltip,
  },

  props: {
    mode: {
      type: String as PropType<IAttachmentMode>,
      default: 'view',
    },

    name: {
      type: String,
      required: true,
    },

    size: {
      type: Number,
      default: 0,
    },

    type: {
      type: String as PropType<IAttachmentType>,
      default: null,
    },

    progress: {
      type: Number as PropType<number | null>,
      default: null,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    maxWidth: {
      type: Number,
      default: 200,
    },
  },

  // emits: [
  //   'click',
  //   'click:remove',
  // ],

  // slots: ['icon'],

  computed: {
    isPreviewMode(): boolean {
      return this.mode === 'view';
    },

    isEditMode(): boolean {
      return this.mode === 'edit';
    },

    isRemoveButtonVisible(): boolean {
      return this.isEditMode;
    },

    extension(): string | undefined {
      if (!this.name) return '';

      const parts = this.name.split('.');

      return parts[parts.length - 1];
    },

    fileType(): IAttachmentType {
      return this.type ?? getTypeForExtension(this.extension) ?? 'unknown';
    },

    fileIcon(): string {
      return getIconForType(this.fileType);
    },

    fileIconColor(): string {
      return this.isDisabled ? 'tt-light-mono-16--text' : getIconColorForType(this.fileType);
    },

    fileSize(): string {
      return humanReadableFileSize(this.size, 1).replace('.', ',');
    },

    classNames(): Record<string, boolean> {
      return {
        [`tt-attachment--${this.mode}`]: true,
        'tt-attachment--disabled': this.isDisabled,
      };
    },

    iconClassNames(): Record<string, boolean> {
      return {
        'tt-light-mono-16--text': this.isDisabled,
      };
    },

    nameClassNames(): Record<string, boolean> {
      return {
        'tt-light-mono-24--text': this.isDisabled,
      };
    },
  },

  methods: {
    onClickHandler(event: MouseEvent) {
      event.stopPropagation();

      this.$emit('click');
    },

    onClickRemoveHandler(event: MouseEvent) {
      event.stopPropagation();

      this.$emit('click:remove');
    },
  },
});
