<template>
  <div class="tt-menu">
    <VMenu
      v-bind="$props"
      v-on="$listeners"
    />
  </div>
</template>

<script>
export default {
  name: 'TTMenu',
};
</script>

<style lang="scss">
@import "TTMenu";

</style>
