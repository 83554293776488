<template>
  <VAvatar
    v-if="showAvatar"
    data-test="tt-avatar"
    :size="size"
    :color="imgColor"
  >
    <VImg
      v-if="hasUrl && !hasImageLoadError"
      data-test="tt-avatar-img"
      :src="url"
      :size="size"
      @error="handleError"
    />

    <p
      v-else-if="showText || hasImageLoadError"
      data-test="tt-avatar-text"
      class="mb-0 text-h6"
      :class="textClasses"
      :style="textStyles"
    >
      <slot>
        {{ twoFirstLetters }}
      </slot>
    </p>
  </VAvatar>
</template>

<script>
export default {
  name: 'TTAvatar',

  props: {
    size: {
      type: [String, Number],
      default: 46,
    },

    url: {
      type: String,
      default: '',
    },

    text: {
      type: String,
      default: '',
    },

    color: {
      type: String,
      default: '',
    },

    textNone: {
      type: Boolean,
      default: false,
    },

    textUppercase: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hasImageLoadError: false,
    };
  },

  computed: {
    hasUrl() {
      return Boolean(this.url);
    },

    hasText() {
      return Boolean(this.text);
    },

    hasColor() {
      return Boolean(this.color);
    },

    showText() {
      return Number(this.size) >= 18;
    },

    showAvatar() {
      return (this.hasUrl && !this.hasImageLoadError) || this.showText;
    },

    imgColor() {
      const DEFAULT_COLOR = 'tt-light-blue pale';
      const color = this.hasColor ? this.color : DEFAULT_COLOR;

      if (this.hasImageLoadError) {
        return color;
      }

      return this.hasUrl ? '' : color;
    },

    textClasses() {
      const classes = {
        'tt-light-blue--text text--vibrant': this.imgColor !== 'tt-black',
        'white--text': this.imgColor === 'tt-black',
        'text-none': this.textNone,
        'text-uppercase': this.textUppercase,
      };

      return classes;
    },

    textStyles() {
      const dict = {
        96: 32,
        46: 12,
        24: 8,
      };

      const styles = {};

      styles.fontSize = `${dict[this.size] || Number.parseInt(this.size, 10) / 2}px !important`;

      return styles;
    },

    twoFirstLetters() {
      const DEFAULT_TEXT = 'TT';
      let str = this.hasText ? this.text : DEFAULT_TEXT;

      if (str.length < 2) {
        str = DEFAULT_TEXT;
      }

      if (str.length > 2) {
        str = str.substring(0, 2);
      }

      if (this.textUppercase) {
        str = str.toUpperCase();
      }

      return str;
    },
  },

  watch: {
    url() {
      this.hasImageLoadError = false;
    },
    hasImageLoadError(v) {
      this.$emit('error', v);
    },
  },

  methods: {
    handleError() {
      this.hasImageLoadError = true;
    },
  },
};
</script>
