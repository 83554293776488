<template>
  <VTextarea
    v-bind="{...$attrs, ...$props}"
    outlined
    filled
    flat
    hide-details="auto"
    :error-messages="errorMessages"
    :placeholder="placeholder"
    :color="color"
    :error="error"
    :value="value"
    :rules="rules"
    :validate-on-blur="validateOnBlur"
    :disabled="disabled"
    :height="height"
    :rows="rows"
    :auto-grow="autoGrow"
    @input="(e) => $emit('input', e)"
    @blur="(e) => $emit('blur', e)"
    @click="(e) => $emit('click', e)"
    @focus="(e) => $emit('focus', e)"
  />
</template>

<script>
export default {
  name: 'TTTextarea',
  props: {
    error: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    height: [String, Number],
    rows: {
      type: Number,
      default: 4,
    },
    color: {
      type: String,
      default: '$tt-light-mono-8 base',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    errorMessages: {
      type: [Array, String],
      default: () => [],
    },
    autoGrow: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    validateOnBlur: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">

@import "TTTextarea/TTTextarea";

::v-deep .v-textarea {
  textarea {
    outline: none;
    border-radius: $border-radius-root;
    border-color: map-deep-get($tt-light-mono-16, 'base');
    border-width: 1px;
    background-color: map-deep-get($tt-light-mono-4, 'base');
    width: 100%;
    padding: 12px;
    caret-color: map-deep-get($tt-light-mono-100, 'base');
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.61px;
  }
}

.v-text-field--outlined.v-input--is-focused ::v-deep fieldset,
.v-text-field--outlined.v-input--has-state ::v-deep fieldset {
  border: 1px solid map-get($tt-light-mono-16, 'base');
}

</style>
