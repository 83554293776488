






























import Vue from 'vue';

const items1 = [
  {
    text: 'Level 1',
    to: '/',
  },
  {
    text: 'Level 2',
    to: '/uikit',
  },
  {
    text: 'Хлебные крошки',
    to: '/uikit/breadcrumbs',
  },
];

const items2 = (new Array(6)).fill(null).map((_, index, array) => ({
  text: `Level ${index + 1}`,
  href: '#',
  disabled: index === array.length - 1,
}));

const items3 = [
  'TalentTech',
  'Управление внутренней разработки информационных продуктов',
  'Отдел развития цифровых технологий, искусственного технологий, искусственного',
  'Управление внутренней разработки информационных продуктов',
].map((text, index, array) => ({
  text,
  href: '#',
  disabled: index === array.length - 1,
}));

export default Vue.extend({
  name: 'TTBreadcrumbsExample',
  data: () => ({
    items1,
    items2,
    items3,
  }),
});
