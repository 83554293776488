

















import Vue from 'vue';

export default Vue.extend({
  name: 'TTSwitch',
  model: {
    event: 'change',
  },
  props: {
    dataTest: {
      type: String,
      default: 'tt-switch',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'tt-primary',
    },
    dense: Boolean,
    loading: Boolean,
    disabled: Boolean,
    readonly: Boolean,
  },
});
