




































































import Vue from 'vue';

export default Vue.extend({
  name: 'TTChipGroupExample',
  data: () => ({
    selectedChips1: [],
    selectedChips2: [],
    selectedChips3: [],

    options: [
      { title: 'В работе' },
      { title: 'Приостановлено' },
      { title: 'Выполнено' },
    ],

    optionsWithIcons: [
      {
        title: 'В работе',
        icon: 'fas fa-clock',
        color: 'warning',
      },
      {
        title: 'Удалено',
        icon: 'fas fa-times-circle',
        color: 'error',
      },
      {
        title: 'Выполнено',
        icon: 'fas fa-check-circle',
        color: 'success',
      },
    ],
  }),
});
