<template>
  <section class="tt-dark-mono-0">
    <VRow>
      <VCol offset="2">
        <h2 class="tt-light-mono-0--text">
          {{ title }}
        </h2>
      </VCol>
    </VRow>

    <VRow
      v-for="(colors, a) in chunkedDarkColors"
      :key="a"
    >
      <VCol
        v-for="(color, b) in colors"
        :key="b"
        cols="auto"
        :offset="b > 0 ? 0 : 2"
      >
        <VCard
          width="76"
          height="54"
          :class="color.classes"
          :style="color.styles"
          elevation="0"
        />

        <h3 class="tt-text-caption tt-light-mono-64--text mt-2">
          {{ color.title }}
        </h3>

        <h4 class="tt-text-caption tt-light-mono-64--text">
          {{ color.subtitle }}
        </h4>
      </VCol>
    </VRow>
  </section>
</template>

<script>
/* eslint-disable vue/require-name-property */
import TTColors from '@/ui/uikit/themes/tt.colors';

export default {
  data() {
    return {
      title: 'Dark',
    };
  },

  computed: {
    darkColors() {
      const capitalize = (str) => str[0].toUpperCase() + str.slice(1);

      const darkColors = Object.keys(TTColors)
        .filter((k) => k.startsWith('tt-dark-'))
        .reduce((acc, curr) => {
          const colors = TTColors[curr];

          Object.keys(colors).forEach((k) => {
            const classes = [curr, k];
            const value = colors[k];
            const styles = curr === 'tt-dark-mono-0'
              ? `border: 1px solid ${TTColors['tt-dark-mono-8'].base} !important;`
              : '';

            let title = curr
              .substring('tt-'.length)
              .split('-')
              .map(capitalize)
              .join(' ');
            let subtitle = capitalize(k);

            if (curr.startsWith('tt-dark-mono-')) {
              title = 'Dark Mono';
              subtitle = curr.substring('tt-dark-mono-'.length);
            }

            const color = {
              classes,
              styles,
              value,
              title,
              subtitle,
            };

            acc.push(color);
          });

          return acc;
        }, []);

      return darkColors;
    },

    chunkedDarkColors() {
      const temparray = [];
      let chunk = 5;

      for (let i = 0, j = this.darkColors.length; i < j; i += chunk) {
        chunk = i > 5 ? 4 : 5;
        temparray.push(this.darkColors.slice(i, i + chunk));
      }

      return temparray;
    },
  },
};
</script>
