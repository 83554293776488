// eslint-disable-next-line import/prefer-default-export
export const humanReadableFileSize = (bytes: number, dp: number = 1) => {
  if (bytes === 0) {
    return '0 B';
  }

  const k = 10 ** dp;

  const exp = Math.floor(Math.log(bytes) / Math.log(1024));

  return `${Math.round((bytes / 1024 ** exp) * k) / k} ${' KMGTP'.charAt(exp)}B`;
};
