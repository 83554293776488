<template>
  <section>
    <VRow>
      <VCol>
        <h2>{{ title }}</h2>
      </VCol>
    </VRow>

    <VRow>
      <VCol
        v-for="(example, i) in examples"
        :key="i"
        cols="auto"
      >
        <p class="tt-text-button-2 tt-light-mono-64--text mb-3">
          {{ example.title }}
        </p>

        <TTBtn v-bind="example.componentProps">
          Button text
        </TTBtn>
      </VCol>
    </VRow>
  </section>
</template>

<script>
/* eslint-disable vue/require-name-property */

export default {
  data() {
    return {
      title: 'Secondary',
      examples: [
        {
          title: '56 px',
          componentProps: { color: 'tt-secondary', xLarge: true },
        },

        {
          title: '44 px',
          componentProps: { color: 'tt-secondary', large: true },
        },

        {
          title: '36 px',
          componentProps: { color: 'tt-secondary' },
        },

        {
          title: '28 px',
          componentProps: { color: 'tt-secondary', small: true },
        },
      ],
    };
  },
};
</script>
