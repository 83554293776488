<template>
  <section>
    <VRow>
      <VCol cols="12">
        <VBadge
          inline
          rigth
          color="tt-light-red"
          content="Draft"
        >
          <h2>Сокращения (TTPrompt)</h2>
        </VBadge>
      </VCol>
      <VCol cols="12">
        <span class="tt-text-body-1">Важно:</span>
        <ul>
          <li>Компонент является блочным</li>
          <li>
            <b>observing - true</b> (это пропс включает ResizeObserver - компонент реагирует на изменение собственного
            размера). Значение false - компонент реагирует на window resize
          </li>
          <li>
            <b>sameWidth - false</b> (ширина тултипа === ширине блока, если включен, игнорируется minWidth и maxWidth)
          </li>
        </ul>
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <VCard>
          <VCardTitle>
            Пропсы
          </VCardTitle>
          <VCardText>
            <VRow align="center">
              <VCol cols="6">
                <VSlider
                  v-model="parentWidth"
                  label="Ширина родителя"
                  max="100"
                  min="1"
                  hide-details
                />
              </VCol>
              <VCol cols="3">
                <VSwitch
                  v-model="parentBorder"
                  label="Бордер родителя"
                />
              </VCol>
              <VCol cols="3">
                <VSwitch
                  v-model="parentPadding"
                  label="Паддинг родителя"
                />
              </VCol>
            </VRow>
            <VRow align="center">
              <VCol cols="4">
                <TTSelect
                  v-model="line"
                  label="line - количество строк"
                  :items="(new Array(10)).fill(1).map((a,i) => ++i)"
                  hide-details
                />
              </VCol>
              <VCol cols="4">
                <VSwitch
                  v-model="observing"
                  label="observing"
                  hide-details
                />
              </VCol>
              <VCol cols="4">
                <VSwitch
                  v-model="sameWidth"
                  label="same-width"
                  hide-details
                />
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <div
          :class="{'prompt-example' : parentBorder, 'pa-3' : parentPadding}"
          :style="parentStyle"
        >
          <TTPrompt
            :text="text"
            :line="line"
            :same-width="sameWidth"
            :observing="observing"
          />
        </div>
      </VCol>
    </VRow>
  </section>
</template>

<script>
/* eslint-disable vue/require-name-property */

import TTPrompt from '@uikit/components/TTPrompt/TTPrompt.vue';

export default {
  components: { TTPrompt },
  data() {
    return {
      // eslint-disable-next-line max-len, vue/max-len
      text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi, quaerat, temporibus? Asperiores dignissimos dolore, eligendi eveniet ex facilis fugiat laudantium molestiae mollitia neque nisi perspiciatis placeat possimus sequi sint sunt?',
      line: 1,
      sameWidth: true,
      observing: true,

      parentWidth: 100,
      parentBorder: true,
      parentPadding: true,
    };
  },
  computed: {
    parentStyle() {
      return { width: `${this.parentWidth}%` };
    },
  },
};
</script>

<style scoped>
.prompt-example{
  border: 1px solid black;
}
</style>
