<template>
  <section>
    <VRow>
      <VCol offset="2">
        <h2>{{ title }}</h2>
      </VCol>
    </VRow>

    <VRow
      v-for="(colors, a) in chunkedLightColors"
      :key="a"
    >
      <VCol
        v-for="(color, b) in colors"
        :key="b"
        cols="auto"
        :offset="b > 0 ? 0 : 2"
      >
        <VCard
          width="76"
          height="54"
          :class="color.classes"
          :style="color.styles"
          elevation="0"
        />

        <h3 class="tt-text-caption tt-light-mono-64--text mt-2">
          {{ color.title }}
        </h3>

        <h4 class="tt-text-caption tt-light-mono-64--text">
          {{ color.subtitle }}
        </h4>
      </VCol>
    </VRow>
  </section>
</template>

<script>
/* eslint-disable vue/require-name-property */
import TTColors from '@/ui/uikit/themes/tt.colors';

export default {
  data() {
    return {
      title: 'Light',
    };
  },

  computed: {
    lightColors() {
      const capitalize = (str) => str[0].toUpperCase() + str.slice(1);

      const lightColors = Object.keys(TTColors)
        .filter((k) => k.startsWith('tt-light-'))
        .reduce((acc, curr) => {
          const colors = TTColors[curr];

          Object.keys(colors).forEach((k) => {
            const classes = [curr, k];
            const value = colors[k];
            const styles = curr === 'tt-light-mono-0'
              ? `border: 1px solid ${TTColors['tt-light-mono-8'].base} !important;`
              : '';
            let title = curr
              .substring('tt-'.length)
              .split('-')
              .map(capitalize)
              .join(' ');
            let subtitle = capitalize(k);

            if (curr.startsWith('tt-light-mono-')) {
              title = 'Light Mono';
              subtitle = curr.substring('tt-light-mono-'.length);
            }

            const color = {
              classes,
              styles,
              value,
              title,
              subtitle,
            };

            acc.push(color);
          });

          return acc;
        }, []);

      return lightColors;
    },

    chunkedLightColors() {
      const chunk = 4;
      const temparray = [];

      for (let i = 0, j = this.lightColors.length; i < j; i += chunk) {
        temparray.push(this.lightColors.slice(i, i + chunk));
      }

      return temparray;
    },
  },
};
</script>
