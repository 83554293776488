<template>
  <VRow>
    <VCol>
      <VRow>
        <VCol>
          {{ text }}
        </VCol>
      </VRow>
      <VRow>
        <VCol>
          <TTAlert
            dismissible
            :type="type"
            :title="title"
            :content="content"

            :value="true"
            class="my-3"
          />
          <TTAlert
            dismissible
            :type="type"
            :content="content"

            :value="true"
            class="my-3"
          />
          <TTAlert
            dismissible
            :type="type"
            :title="title"
            :content="content"
            :link-external="linkExternal"

            :value="true"
            class="my-3"
          />
          <TTAlert
            dismissible
            :type="type"
            :title="title"
            :content="content"
            :link-internal="linkInternal"

            :value="true"
            class="my-3"
          />
          <TTAlert
            :type="type"
            :title="title"
            :content="content"
            actions

            :value="true"
            class="my-3"
          />
          <TTAlert
            :type="type"
            :content="content"
            actions

            :value="true"
            class="my-3"
          />
        </VCol>
      </VRow>
    </VCol>
  </VRow>
</template>

<script>
/* eslint-disable vue/require-name-property */

export default {
  data() {
    return {
      type: 'info',
      text: 'Информационное уведомление',
      title: 'Изменения сохранены и отчет отправлен на согласование.',
      content: 'Изменения сохранены и отчет отправлен на согласование.',
      linkExternal: {
        to: 'https://google.com',
        text: 'Смотреть все (external)',
      },
      linkInternal: {
        to: { name: 'alert' },
        text: 'Смотреть все (internal)',
      },
    };
  },
};
</script>
