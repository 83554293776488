<template>
  <VRow>
    <VCol>
      <VRow>
        <VCol>
          {{ text }}
        </VCol>
      </VRow>
      <VCard class="my-6">
        <VCardTitle>
          Пропсы
        </VCardTitle>
        <VCardText>
          <VRow>
            <VCol
              v-for="(value,key) in position"
              :key="key"
              cols="4"
            >
              <VCheckbox
                v-model="position[key]"
                :color="color"
              >
                <template v-slot:label>
                  <span class="black--text">{{ key }}</span>
                </template>
              </VCheckbox>
            </VCol>
            <VCol cols="12">
              <VSlider
                v-model="timeout"
                :tick-labels="timeoutLabels"
                :max="3"
                step="1"
                ticks="always"
                tick-size="4"
              >
                <template v-slot:label>
                  <span class="black--text">timeout</span>
                </template>
              </VSlider>
            </VCol>
          </VRow>
        </VCardText>
      </VCard>
      <VRow>
        <VCol>
          <TTBtn
            :color="color"
            @click="model1=!model1"
          >
            title + content
          </TTBtn>

          <TTNotify
            v-model="model1"
            v-bind="position"
            :timeout="timeoutLabels[timeout]"
            :type="type"
            :title="title"
            :content="content"
          />
        </VCol>
      </VRow>
      <VRow>
        <VCol>
          <TTBtn
            :color="color"
            @click="model2=!model2"
          >
            content
          </TTBtn>

          <TTNotify
            v-model="model2"
            :type="type"
            :content="content"
          />
        </VCol>
      </VRow>
      <VRow>
        <VCol>
          <TTBtn
            :color="color"
            @click="model3=!model3"
          >
            title + content + link-external
          </TTBtn>

          <TTNotify
            v-model="model3"
            :type="type"
            :title="title"
            :content="content"
            :link-external="linkExternal"
          />
        </VCol>
      </VRow>
      <VRow>
        <VCol>
          <TTBtn
            :color="color"
            @click="model4=!model4"
          >
            title + content + link-internal
          </TTBtn>

          <TTNotify
            v-model="model4"
            :type="type"
            :title="title"
            :content="content"
            :link-internal="linkInternal"
          />
        </VCol>
      </VRow>
    </VCol>
  </VRow>
</template>

<script>
/* eslint-disable vue/require-name-property */

export default {
  data() {
    return {
      model1: false,
      model2: false,
      model3: false,
      model4: false,

      position: {
        app: false,
        absolute: false,
        centered: false,
        top: false,
        bottom: false,
        left: false,
        right: false,
      },

      timeout: -1,
      timeoutLabels: [-1, 1000, 5000, 10000],

      color: 'warning',

      type: 'warning',
      text: 'Предупреждение',
      title: 'Изменения сохранены и отчет отправлен на согласование.',
      content: 'Изменения сохранены и отчет отправлен на согласование.',
      linkExternal: {
        to: 'https://google.com',
        text: 'Смотреть все (external)',
      },
      linkInternal: {
        to: { name: 'alert' },
        text: 'Смотреть все (internal)',
      },
    };
  },
};
</script>
