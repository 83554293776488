<template>
  <section>
    <VRow>
      <VCol>
        <VBadge
          inline
          left
          color="tt-light-red"
          content="TODO"
        >
          <h2>{{ title }}</h2>
        </VBadge>
      </VCol>
    </VRow>

    <VRow>
      <VCol
        v-for="(example, i) in examples"
        :key="i"
        cols="auto"
      >
        <p class="tt-text-button-2 tt-light-mono-64--text mb-3">
          {{ example.title }}
        </p>

        <TTBtn v-bind="example.componentProps">
          <VIcon>
            fal fa-angle-left
          </VIcon>
        </TTBtn>
      </VCol>
    </VRow>
  </section>
</template>

<script>
/* eslint-disable vue/require-name-property */

export default {
  data() {
    return {
      title: 'Pill with icon only',
      examples: [
        {
          title: '24 px',
          componentProps: { color: 'tt-secondary', xSmall: true, rounded: true },
        },
      ],
    };
  },
};
</script>
