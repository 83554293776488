











































import { debounce } from 'lodash';
import Vue, { PropType } from 'vue';
import { isElement } from '@/helpers';
import TTAttachment from '../TTAttachment/TTAttachment.vue';
import TTBtn from '../TTBtn/TTBtn.vue';

export type IAttachmentType =
  | 'word'
  | 'excel'
  | 'image'
  | 'powerpoint'
  | 'pdf'
  | 'audio'
  | 'video'
  | 'code'
  | 'archive'
  | 'unknown';

interface IFile {
  name: string;
  size: number;
  type?: IAttachmentType;
  isLoading: boolean;
  isDisabled: boolean;
  progress: number;
}

export default Vue.extend({
  name: 'TTAttachments',

  components: {
    TTAttachment,
    TTBtn,
  },

  props: {
    mode: {
      type: String as PropType<'view' | 'edit'>,
      default: 'view',
    },

    direction: {
      type: String as PropType<'mixed' | 'vertically'>,
      default: 'mixed',
    },

    value: {
      type: Array as PropType<IFile[]>,
      required: true,
    },

    spoiler: {
      type: Boolean,
      default: true,
    },
  },

  // emits: ['click', 'input', 'remove'],

  data() {
    return {
      isAllFilesVisible: false,

      /**
       * Количество отображаемых элементов
       * 0 - значит показать всё
       */
      visibleAttachmentsCount: this.value.length,

      observer: null as ResizeObserver | null,
      observed: false,
    };
  },

  computed: {
    containerClassNames(): Record<string, boolean> {
      return {
        'tt-attachments--mixed': this.direction === 'mixed',
        'tt-attachments--vertically': this.direction === 'vertically',
      };
    },

    totalItemsCount(): number {
      return this.value.length;
    },

    visibleAttachments(): IFile[] {
      return !this.spoiler || this.isAllFilesVisible
        ? this.value
        : this.value.slice(0, this.visibleAttachmentsCount);
    },

    itemsLeftCount(): number {
      return this.totalItemsCount - this.visibleAttachmentsCount;
    },

    isOverflowed(): boolean {
      return this.visibleAttachmentsCount < this.totalItemsCount;
    },

    isTogglerVisible(): boolean {
      return this.spoiler && this.isOverflowed;
    },

    listElement(): Vue | Element | Vue[] | Element[] | undefined {
      return this.$refs.attachments__list;
    },
  },

  watch: {
    spoiler: {
      handler(newValue: boolean) {
        newValue
          ? this.observe()
          : this.unobserve();
      },
    },
  },

  created() {
    this.debouncedResizeHandler = debounce(this.onResize, 25);

    const resizeObserver = new ResizeObserver(this.debouncedResizeHandler);

    this.observer = resizeObserver;
  },

  mounted() {
    this.observe();
  },

  beforeDestroy() {
    this.unobserve();
  },

  activated() {
    this.observe();
  },

  deactivated() {
    this.unobserve();
  },

  destroyed() {
    this.observer?.disconnect();
  },

  methods: {
    debouncedResizeHandler(_: ResizeObserverEntry[]) {},

    observe() {
      if (this.observed) return;
      if (!isElement(this.listElement)) return;

      this.observer?.observe(this.listElement);
      this.observed = true;
    },

    unobserve() {
      if (!isElement(this.listElement)) return;

      this.observer?.unobserve(this.listElement);
      this.observed = false;
    },

    onClickHandler(index: number) {
      this.$emit('click', index);
    },

    onClickRemoveHandler(index: number) {
      const attachments = [...this.value];

      attachments.splice(index, 1);

      this.$emit('input', attachments);
      this.$emit('remove', attachments);
    },

    onClickSpoilerHandler() {
      this.isAllFilesVisible = !this.isAllFilesVisible;
    },

    async onResize(entries: ResizeObserverEntry[]) {
      this.visibleAttachmentsCount = this.totalItemsCount;

      await this.$nextTick();
      await this.$nextTick();
      await this.$nextTick();

      let currentVisibleItemsCount = 1;

      entries.forEach(({ target }) => {
        const { children } = target;

        if (children.length === 0) return;

        const firstElement = children[0];
        const { top: firstElementTopPosition } = firstElement.getBoundingClientRect();

        for (let i = 1; i < children.length; i++) {
          const element = children[i];

          const { top: currentElementTopPosition } = element.getBoundingClientRect();

          if (currentElementTopPosition !== firstElementTopPosition) break;

          currentVisibleItemsCount += 1;
        }

        this.visibleAttachmentsCount = currentVisibleItemsCount;
      });
    },
  },
});
