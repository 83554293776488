import { render, staticRenderFns } from "./TTPrompt.example.vue?vue&type=template&id=2dfe8dda&scoped=true&"
import script from "./TTPrompt.example.vue?vue&type=script&lang=js&"
export * from "./TTPrompt.example.vue?vue&type=script&lang=js&"
import style0 from "./TTPrompt.example.vue?vue&type=style&index=0&id=2dfe8dda&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dfe8dda",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBadge,VCard,VCardText,VCardTitle,VCol,VRow,VSlider,VSwitch})
