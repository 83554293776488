<template>
  <section>
    <VRow>
      <VCol>
        <h2>{{ title }}</h2>
      </VCol>
    </VRow>

    <VRow>
      <VCol
        v-for="(example, i) in primary"
        :key="i"
        cols="auto"
      >
        <p class="tt-text-button-2 tt-light-mono-64--text mb-3">
          {{ example.title }}
        </p>

        <TTBtn v-bind="example.componentProps">
          <VIcon>
            fal fa-arrow-to-bottom
          </VIcon>
        </TTBtn>
      </VCol>
    </VRow>

    <VRow>
      <VCol
        v-for="(example, i) in secondary"
        :key="i"
        cols="auto"
      >
        <TTBtn v-bind="example.componentProps">
          <VIcon>
            fal fa-arrow-to-bottom
          </VIcon>
        </TTBtn>
      </VCol>
    </VRow>

    <VRow>
      <VCol
        v-for="(example, i) in danger"
        :key="i"
        cols="auto"
      >
        <TTBtn v-bind="example.componentProps">
          <VIcon>
            fal fa-arrow-to-bottom
          </VIcon>
        </TTBtn>
      </VCol>
    </VRow>

    <VRow>
      <VCol
        v-for="(example, i) in ghost"
        :key="i"
        cols="auto"
      >
        <TTBtn v-bind="example.componentProps">
          <VIcon>
            fal fa-arrow-to-bottom
          </VIcon>
        </TTBtn>
      </VCol>
    </VRow>

    <VRow>
      <VCol
        v-for="(example, i) in unactive"
        :key="i"
        cols="auto"
      >
        <TTBtn v-bind="example.componentProps">
          <VIcon>
            fal fa-arrow-to-bottom
          </VIcon>
        </TTBtn>
      </VCol>
    </VRow>
  </section>
</template>

<script>
/* eslint-disable vue/require-name-property */

export default {
  data() {
    return {
      title: 'With icon only',

      primary: [
        {
          title: '56 px',
          componentProps: { color: 'tt-primary', square: true, xLarge: true },
        },

        {
          title: '44 px',
          componentProps: { color: 'tt-primary', square: true, large: true },
        },

        {
          title: '36 px',
          componentProps: { color: 'tt-primary', square: true },
        },

        {
          title: '28 px',
          componentProps: { color: 'tt-primary', square: true, small: true },
        },
      ],

      secondary: [
        {
          title: '56 px',
          componentProps: { color: 'tt-secondary', square: true, xLarge: true },
        },

        {
          title: '44 px',
          componentProps: { color: 'tt-secondary', square: true, large: true },
        },

        {
          title: '36 px',
          componentProps: { color: 'tt-secondary', square: true },
        },

        {
          title: '28 px',
          componentProps: { color: 'tt-secondary', square: true, small: true },
        },
      ],

      danger: [
        {
          title: '56 px',
          componentProps: { color: 'tt-danger', square: true, xLarge: true },
        },

        {
          title: '44 px',
          componentProps: { color: 'tt-danger', square: true, large: true },
        },

        {
          title: '36 px',
          componentProps: { color: 'tt-danger', square: true },
        },

        {
          title: '28 px',
          componentProps: { color: 'tt-danger', square: true, small: true },
        },
      ],

      ghost: [
        {
          title: '56 px',
          componentProps: {
            color: 'tt-ghost',
            square: true,
            plain: true,
            xLarge: true,
          },
        },

        {
          title: '44 px',
          componentProps: {
            color: 'tt-ghost',
            square: true,
            plain: true,
            large: true,
          },
        },

        {
          title: '36 px',
          componentProps: { color: 'tt-ghost', square: true, plain: true },
        },

        {
          title: '28 px',
          componentProps: {
            color: 'tt-ghost',
            square: true,
            plain: true,
            small: true,
          },
        },
      ],

      unactive: [
        {
          title: '56 px',
          componentProps: {
            color: 'tt-unactive',
            square: true,
            disabled: true,
            xLarge: true,
          },
        },

        {
          title: '44 px',
          componentProps: {
            color: 'tt-unactive',
            square: true,
            disabled: true,
            large: true,
          },
        },

        {
          title: '36 px',
          componentProps: { color: 'tt-unactive', square: true, disabled: true },
        },

        {
          title: '28 px',
          componentProps: {
            color: 'tt-unactive',
            square: true,
            disabled: true,
            small: true,
          },
        },
      ],
    };
  },
};
</script>
