<template>
  <section>
    <VRow>
      <VCol>
        <VBadge
          inline
          left
          color="tt-light-red"
          content="TODO"
        >
          <h2>{{ title }}</h2>
        </VBadge>
      </VCol>
    </VRow>

    <VRow>
      <VCol
        v-for="(example, i) in examples"
        :key="i"
        cols="auto"
      >
        <p class="tt-text-button-2 tt-light-mono-64--text mb-3">
          {{ example.title }}
        </p>

        <TTTooltip v-bind="example.componentProps">
          <template #activator="{ value, attrs }">
            <TTBtn
              :value="value"
              v-bind="{ ...example.activatorProps, ...attrs }"
            >
              <VIcon>
                fal fa-arrow-to-bottom
              </VIcon>
            </TTBtn>
          </template>

          <span>Button</span>
        </TTTooltip>
      </VCol>
    </VRow>
  </section>
</template>

<script>
/* eslint-disable vue/require-name-property */

export default {
  data() {
    return {
      title: 'Round with text at the bottom',
      examples: [
        {
          title: '44 px',
          componentProps: { value: true, bottom: true },
          activatorProps: {
            color: 'tt-secondary', fab: true, large: true,
          },
        },

        {
          title: '36 px',
          componentProps: { value: true, bottom: true },
          activatorProps: { color: 'tt-secondary', fab: true },
        },
      ],
    };
  },
};
</script>
