<template>
  <section>
    <VRow>
      <VCol>
        <h2>{{ title }}</h2>
      </VCol>
    </VRow>

    <VRow>
      <VCol
        v-for="(example, i) in fab"
        :key="i"
        cols="auto"
      >
        <p class="tt-text-button-2 tt-light-mono-64--text mb-3">
          {{ example.title }}
        </p>

        <TTBtn v-bind="example.componentProps">
          <VIcon>
            fal fa-arrow-to-bottom
          </VIcon>
        </TTBtn>
      </VCol>
    </VRow>
  </section>
</template>

<script>
/* eslint-disable vue/require-name-property */

export default {
  data() {
    return {
      title: 'Round with icon only',

      fab: [
        {
          title: '44 px',
          componentProps: {
            fab: true, color: 'white', elevation: 1, large: true,
          },
        },

        {
          title: '36 px',
          componentProps: { fab: true, color: 'white', elevation: 1 },
        },

        {
          title: '28 px',
          componentProps: {
            fab: true, color: 'white', elevation: 1, small: true,
          },
        },
      ],
    };
  },
};
</script>
