<template>
  <section>
    <VRow>
      <VCol>
        <h2>{{ title }}</h2>
      </VCol>
    </VRow>

    <VRow>
      <VCol
        v-for="(example, i) in primary"
        :key="i"
        cols="auto"
      >
        <p class="tt-text-button-2 tt-light-mono-64--text mb-3">
          {{ example.title }}
        </p>

        <TTBtn v-bind="example.componentProps">
          Button text

          <VIcon right>
            fal fa-arrow-to-bottom
          </VIcon>
        </TTBtn>
      </VCol>
    </VRow>

    <VRow>
      <VCol
        v-for="(example, i) in secondary"
        :key="i"
        cols="auto"
      >
        <TTBtn v-bind="example.componentProps">
          Button text

          <VIcon right>
            fal fa-arrow-to-bottom
          </VIcon>
        </TTBtn>
      </VCol>
    </VRow>

    <VRow>
      <VCol
        v-for="(example, i) in danger"
        :key="i"
        cols="auto"
      >
        <TTBtn v-bind="example.componentProps">
          Button text

          <VIcon right>
            fal fa-arrow-to-bottom
          </VIcon>
        </TTBtn>
      </VCol>
    </VRow>

    <VRow>
      <VCol
        v-for="(example, i) in ghost"
        :key="i"
        cols="auto"
      >
        <TTBtn v-bind="example.componentProps">
          Button text

          <VIcon right>
            fal fa-arrow-to-bottom
          </VIcon>
        </TTBtn>
      </VCol>
    </VRow>

    <VRow>
      <VCol
        v-for="(example, i) in unactive"
        :key="i"
        cols="auto"
      >
        <TTBtn v-bind="example.componentProps">
          Button text

          <VIcon right>
            fal fa-arrow-to-bottom
          </VIcon>
        </TTBtn>
      </VCol>
    </VRow>
  </section>
</template>

<script>
/* eslint-disable vue/require-name-property */

export default {
  data() {
    return {
      title: 'With icon on the right',

      primary: [
        {
          title: '56 px',
          componentProps: { color: 'tt-primary', xLarge: true },
        },

        {
          title: '44 px',
          componentProps: { color: 'tt-primary', large: true },
        },

        {
          title: '36 px',
          componentProps: { color: 'tt-primary' },
        },

        {
          title: '28 px',
          componentProps: { color: 'tt-primary', small: true },
        },
      ],

      secondary: [
        {
          title: '56 px',
          componentProps: { color: 'tt-secondary', xLarge: true },
        },

        {
          title: '44 px',
          componentProps: { color: 'tt-secondary', large: true },
        },

        {
          title: '36 px',
          componentProps: { color: 'tt-secondary' },
        },

        {
          title: '28 px',
          componentProps: { color: 'tt-secondary', small: true },
        },
      ],

      danger: [
        {
          title: '56 px',
          componentProps: { color: 'tt-danger', xLarge: true },
        },

        {
          title: '44 px',
          componentProps: { color: 'tt-danger', large: true },
        },

        {
          title: '36 px',
          componentProps: { color: 'tt-danger' },
        },

        {
          title: '28 px',
          componentProps: { color: 'tt-danger', small: true },
        },
      ],

      ghost: [
        {
          title: '56 px',
          componentProps: { color: 'tt-ghost', plain: true, xLarge: true },
        },

        {
          title: '44 px',
          componentProps: { color: 'tt-ghost', plain: true, large: true },
        },

        {
          title: '36 px',
          componentProps: { color: 'tt-ghost', plain: true },
        },

        {
          title: '28 px',
          componentProps: { color: 'tt-ghost', plain: true, small: true },
        },
      ],

      unactive: [
        {
          title: '56 px',
          componentProps: { color: 'tt-unactive', disabled: true, xLarge: true },
        },

        {
          title: '44 px',
          componentProps: { color: 'tt-unactive', disabled: true, large: true },
        },

        {
          title: '36 px',
          componentProps: { color: 'tt-unactive', disabled: true },
        },

        {
          title: '28 px',
          componentProps: { color: 'tt-unactive', disabled: true, small: true },
        },
      ],
    };
  },
};
</script>
